import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss']
})
export class LicenceComponent implements OnInit {

  index: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(next => {
      let activeTab = next.get('action');
      console.log(next)
      switch (activeTab) {
        case 'transmission':
          this.index = 1;
          break;
        default:
          this.index = 0;
          break;
      }
    });
  }

  changeTab(event: { index: number }) {
    switch (event.index) {
      case 0:
        this.router.navigate(['license', 'administrate', 'activation']);
        break;
      case 1:
        this.router.navigate(['license', 'administrate', 'transmission']);
        break;
    }
  }

}
