import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LicenceComponent } from './licence/licence.component';
import { LicenseTransmissionStateComponent } from './licence/license-transmission-state/license-transmission-state.component';

const routes: Routes = [

  { path: 'license', /*                     */redirectTo: '/license/administrate', /*               */pathMatch: 'full' },
  { path: 'license/administrate/', /*       */redirectTo: '/license/administrate/activation', /*    */pathMatch: 'full' },
  { path: 'license/administrate/:action', /**/component: LicenceComponent, /*                       */pathMatch: 'full' },
  { path: 'license/administrate/:action', /**/component: LicenceComponent, /*                       */pathMatch: 'full' },
  { path: 'license/administrate/:action', /**/component: LicenceComponent, /*                       */pathMatch: 'full' },
  { path: 'license/transmission/:state', /* */component: LicenseTransmissionStateComponent, /*      */pathMatch: 'full' },

  { path: '',  /*                           */redirectTo: '/license/administrate/activation', /*                             */pathMatch: 'full' },

  { path: '**', /*                          */redirectTo: '', /*                                    */pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
