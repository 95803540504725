import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { LicenseActivationComponent } from './license-activation.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ClipboardModule } from 'ngx-clipboard';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClipboardModule,
    // <primeNG>
    CardModule,
    InputTextareaModule,
    InputTextModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    // </primeNG>
  ],
  declarations: [
    LicenseActivationComponent,
  ],
  exports: [
    LicenseActivationComponent,
  ]
})
export class LicenseActivationModule { }
