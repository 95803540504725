import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestApiConstants } from '../shared/api/rest-api.constants';

@Injectable({
  providedIn: 'root'
})
export class RestService {


  public readonly restApiConstants = new RestApiConstants();
  public server: string;
  public readonly readyPromise: Promise<this>;

  headers: {
    'Authorization': string;
  } = { 'Authorization': '' };
  sessionIdCache: string;

  constructor(
    public http: HttpClient,
    // configService: ConfigService,
    // cookieReplacementService: CookieReplacementService,
  ) {
    this.server = "http://localhost:4200/api";
    this.headers = {
      'Authorization': 'Bearer 0000'
    };
    this.readyPromise = Promise.resolve(this);
  }

  public get<T>(url: string): Promise<T> {
    return this.getO<T>(url)
      .toPromise();
  }

  public getO<T>(url: string): Observable<T> {
    return this.http
      .get<T>(this.server + url, { withCredentials: true, headers: this.headers });
  }


  public post<T>(url: string, data: object): Promise<T> {
    console.log('POST ' + this.server + url, data);
    return this.postO<T>(
      this.server + url,
      data
    )
      .toPromise();
  }

  public postO<T>(url: string, data: object): Observable<T> {
    console.log('POST ' + this.server + url, data);
    return this.http
      .post<T>(
        this.server + url,
        data,
        { headers: this.headers }
      );
  }

  public delete<T>(url: string): Promise<T> {
    return this.deleteO<T>(url)
      .toPromise();
  }

  public deleteO<T>(url: string): Observable<T> {
    return this.http
      .delete<T>(this.server + url, { withCredentials: true, headers: this.headers });
  }

}

