import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LicenseActivationModule } from './licence/license-activation/license-activation.module';
import { LicenseTransmissionModule } from './licence/license-transmission/license-transmission.module';
import { TabViewModule } from 'primeng/tabview';
import { HttpClientModule } from '@angular/common/http';
import { LicenseTransmissionStateModule } from './licence/license-transmission-state/license-transmission-state.module';
import { LicenceComponent } from './licence/licence.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LicenseActivationModule,
    LicenseTransmissionModule,
    LicenseTransmissionStateModule,
    // <primeNG>
    TabViewModule,
    // <primeNG>
  ],
  declarations: [
    AppComponent,
    LicenceComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
