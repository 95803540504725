import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api/message';

@Component({
  selector: 'app-license-transmission-state',
  templateUrl: './license-transmission-state.component.html',
  styleUrls: ['./license-transmission-state.component.scss']
})
export class LicenseTransmissionStateComponent implements OnInit {
  msgsSuccess: Array<Message> = [{
    severity: 'success',
    summary: 'The transmission was successful.'
  }];
  msgsFailed: Array<Message> = [{
    severity: 'error',
    summary: 'The transmission was not successful.',
    detail: 'Please contact our support team.',
  }];

  state: 'success' | 'failed';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(next => {
      let state = next.get('state');
      console.log(next)
      switch (state) {
        case 'failed': /* FALL-THROUGH */
        case 'success':
          this.state = state;
          break;
        default:
          this.router.navigate([]);
          break;
      }
    });
  }

}
