<p-messages [(value)]="msgs"></p-messages>

<h2>License Transmission</h2>
<p-card *ngIf="false">
  <div>
    Here you can activate the license for your Frequture product if you have a valid activition code.
    Enter the code into the text box, add your e-mail address (for identification purpose) and click on the button.
    You will get the license code immediately.
    With this code, you can start using your Frequture product (and you should save this code for future usage!).
  </div>
  <div>
    <b>Important:</b> The validity period starts with the click on the button below.
  </div>
</p-card>

<div class="column">
  <span class="ui-float-label">
    <textarea
          pInputTextarea
          id="activationCode-transmission-input"
          class="licence-textarea"
          [ngClass]="{ 'ui-state-filled': !!activationCode }"
          [(ngModel)]="activationCode"
          required="required" >
    </textarea>
    <label for="activationCode-transmission-input">Activation or license code</label>
  </span>
</div>

<div class="p-grid margin">
  <div class="p-col-7">
    <span class="ui-float-label">
      <input
            pInputText
            id="mail-input-transform"
            type="text"
            autocomplete="email"
            [(ngModel)]="oldEmail"
            required="required" />
      <label for="mail-input-transform">Old e-mail address</label>
    </span>
  </div>
</div>

<div class="p-grid margin">
  <div class="p-col-7">
    <span class="ui-float-label">
      <input
            pInputText
            id="new-mail-input-transmission"
            type="text"
            [(ngModel)]="newEmail"
            required="required" />
      <label for="new-mail-input-transmission">New e-mail address</label>
    </span>
  </div>
</div>

<div class="p-grid margin">
  <div class="p-col-7">
    <span class="ui-float-label">
      <input
            pInputText
            id="new-mail-input-transmission-confim"
            type="text"
            [(ngModel)]="newEmailConfirm"
            required="required" />
      <label for="new-mail-input-transmission-confim">Confirm new e-mail address</label>
    </span>
  </div>
</div>


<div class="p-grid">
  <div class="p-col-7">
    <button
          [disabled]="!isValidInput()"
          pButton
          type="button"
          label="Transmit your license!"
          (click)="submit()">
    </button>
  </div>
</div>


