import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseTransmissionStateComponent } from './license-transmission-state.component';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';


@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
  ],
  declarations: [
    LicenseTransmissionStateComponent,
  ],

})
export class LicenseTransmissionStateModule { }
