<p-messages [(value)]="msgs"></p-messages>

<h2>License Activation</h2>
<p-card *ngIf="false">
  <div>
    Here you can activate the license for your Frequture product if you have a valid activition code.
    Enter the code into the text box, add your e-mail address (for identification purpose) and click on the button.
    You will get the license code immediately.
    With this code, you can start using your Frequture product (and you should save this code for future usage!).
  </div>
  <div>
    <b>Important:</b> The validity period starts with the click on the button below.
  </div>
</p-card>

<div class="column">
  <span class="ui-float-label">
    <textarea
          pInputTextarea
          id="activationCode-input"
          class="licence-textarea"
          [(ngModel)]="activationCode"
          required="required" >
    </textarea>
    <label for="activationCode-input">Activation code</label>
  </span>
</div>

<div class="p-grid margin">
  <div class="p-col-7">
    <span class="ui-float-label">
      <input
            pInputText
            id="mail-input"
            type="text"
            autocomplete="email"
            [(ngModel)]="email"
            required="required" />
      <label for="mail-input">E-mail address</label>
    </span>
  </div>
</div>

<div class="p-grid margin">
  <div class="p-col-7">
    <span class="ui-float-label">
      <input
            pInputText
            id="mail-input-confim"
            type="text"
            autocomplete="email"
            [(ngModel)]="emailConfirm"
            required="required" />
      <label for="mail-input-confim">Confirm e-mail address</label>
    </span>
  </div>
</div>


<div class="p-grid" *ngIf="!licenseCode">
  <div class="p-col-7">
    <button
          [disabled]="!email || !activationCode"
          pButton
          type="button"
          label="Activate your license!"
          (click)="submit()">
    </button>
  </div>
</div>

<div class="p-grid" *ngIf="licenseCode">
  <div class="p-col-7">
    Here is your licence code (click to copy):
    <div>
      <textarea
            type="text"
            pInputTextarea
            [(ngModel)]="licenseCode"
            disabled="disabled"
            (click)="copyToClipboard()">
      </textarea>
    </div>
    You've got it by mail as well.
  </div>
</div>

