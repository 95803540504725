export class RestApiConstants {

    public readonly API_VERSION = '1';
    public readonly API_PREFIX = '/v' + this.API_VERSION;

    public readonly ADMIN_INFIX = '/admin';
    public readonly USER_INFIX = '/user';
    public readonly LICENSE_INFIX = '/license';
    public readonly ACTIVATION_CODE_INFIX = '/activation-code';

}