import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { Message } from 'primeng/api/message';
import { Subscription } from 'rxjs';
import { LicenseTransmissionRequest } from '../../../shared/license/license-transmission-request';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Component({
  selector: 'app-license-transmission',
  templateUrl: './license-transmission.component.html',
  styleUrls: ['./license-transmission.component.scss']
})
export class LicenseTransmissionComponent implements OnInit {

  oldEmail: string;
  newEmail: string;
  newEmailConfirm: string;

  activationCode: string;
  msgs: Array<Message> = [];

  private previousRequest: Subscription;
  isButtonEnabled: boolean = true;

  constructor(
    private restService: RestService,
  ) { }

  ngOnInit(): void {
    this.msgs.push({
      severity: "info",
      summary: "Multiple transmission requests",
      detail: "Starting a new transmission for the same license will invalidate previous requests."
    });
  }

  submit() {
    if (this.isValidInput()) {
      let request: LicenseTransmissionRequest = {
        activationOrLicenceCode: this.activationCode,
        oldEmail: this.oldEmail,
        newEmail: this.newEmail
      };
      this.restService
        .readyPromise
        .then(rs => {
          if (this.previousRequest) {
            this.previousRequest.unsubscribe();
          }
          this.previousRequest = rs.postO<{ licenseCode: string }>(
            // '/v1/admin/activation/1/11',
            rs.restApiConstants.API_PREFIX + rs.restApiConstants.LICENSE_INFIX + "/transmit",
            request
          )
            .subscribe(
              (next: { licenseCode: string }) => {
                this.isButtonEnabled = false;
                this.msgs = [{
                  severity: 'success',
                  summary: "Transmission prepared",
                  detail: "You've got a mail (also check your spam folder). Please click on the confirmation link in the mail to finish the license transmission process."
                }];

              },
              err => {
                this.msgs = [{
                  severity: 'error',
                  summary: 'The licence transmission could not be processed',
                  detail: 'Please contact our support team.'
                }];
              },
              () => this.previousRequest.unsubscribe()
            );
        })
    }
  }

  isValidInput(): boolean {
    return this.oldEmail
      && this.newEmail
      && this.newEmailConfirm
      && this.newEmail.trim() === this.newEmailConfirm.trim()
      && this.oldEmail.includes("@")
      && this.newEmail.includes("@");
  }
}
