import { Component, OnInit } from '@angular/core';
import { LicenseActivationRequest } from '../../../shared/license/license-activation-request';
import { RestService } from '../../rest.service';
import { ClipboardService } from 'ngx-clipboard'
import { Message } from 'primeng/api/message';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-license-activation',
  templateUrl: './license-activation.component.html',
  styleUrls: ['./license-activation.component.scss']
})
export class LicenseActivationComponent implements OnInit {

  activationCode: string;
  email: string;
  emailConfirm: string;
  licenseCode: string;
  msgs: Array<Message> = [];

  private previousRequest: Subscription;

  constructor(
    private restService: RestService,
    private _clipboardService: ClipboardService,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (
      this.activationCode
      && this.email
      && this.emailConfirm
      && this.email.toLocaleLowerCase().trim() === this.emailConfirm.toLocaleLowerCase().trim()
    ) {
      let request: LicenseActivationRequest = {
        activationCode: this.activationCode.trim(),
        email: this.email.toLocaleLowerCase().trim()
      };
      this.restService
        .readyPromise
        .then(rs => {
          if (this.previousRequest) {
            this.previousRequest.unsubscribe();
          }
          this.previousRequest = rs.postO<{ licenseCode: string }>(
            // '/v1/admin/activation/1/11',
            rs.restApiConstants.API_PREFIX + rs.restApiConstants.LICENSE_INFIX + "/generate",
            request
          )
            .subscribe(
              (next: { licenseCode: string }) => {
                this.msgs = [];
                this.licenseCode = next.licenseCode;
              },
              err => {
                this.email = null;
                this.emailConfirm = null;
                this.msgs = [{
                  severity: 'error',
                  summary: 'Your activation could not be processed',
                  detail: 'Please contact our support team.'
                }];
              },
              () => this.previousRequest.unsubscribe()
            );
        })
    }
  }

  copyToClipboard() {
    this._clipboardService.copyFromContent(this.licenseCode);
  }

}
